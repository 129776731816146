<script>
  import { mapActions} from "vuex";
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';

  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  import serverConfig from '@/helpers/config';

  import documentImportManager from "@/components/widgets/documentImportManager";

  export default {
    setup() {

        let tk = 'Bearer '+localStorage.getItem('tk')
        return { tk };
    },
    page: {
      title: "Gestione canali",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Gestione canali",
        dataEdit:{},
        item:{
            tipo: '',
            Codice : '',
            Nome : '',
            Descrizione : '',
            CodCli: '',
            Sconto: '',
            CodArt:'',
            CodMarca: '',

            scontoPercBase:0,
            scontoPercAggiuntivo:0,
        },
        items: [
          {
            text: "Gestione canali",
            active: true,
          },
        ],
        date1: null,
        date: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        searchQuery: null,
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions2: {
          animationData: animationData1
        },
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        itemList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        myLabels: {
          footerLeft: (top, bottom, total) => `Documenti ${top} di ${bottom} visualizzati di ${total} totali`,
          first: 'Prima',
          previous: 'Precedente',
          next: 'Prossima',
          last: 'Ultima',
          footerRight: {
              selected: 'Selezionato:',
              filtered: 'Filtrato:',
              loaded: 'Caricato:'
          },
          processing: 'Elaborando',
          tableSetting: 'Impostazioni tabella',
          exportExcel: 'Esporta Excel',
          importExcel: 'Importa Excel',
          back: 'Indietro',
          reset: 'Impostazioni di base',
          sortingAndFiltering: 'Ordina e Filtra',
          sortAscending: 'Ordine Ascendente',
          sortDescending: 'Ordine Descendente',
          near: '≒ Vicino',
          exactMatch: '= Corrispondenza Esatta',
          notMatch: '≠ Nessuna Corrispondenza',
          greaterThan: '&gt; Maggiore di',
          greaterThanOrEqualTo: '≥ Maggiore o Uguale a',
          lessThan: '&lt; Minore di',
          lessThanOrEqualTo: '≤ Minore o Uguale a ',
          regularExpression: '~ Espressione Regolare',
          customFilter: 'Personalizza Filtro',
          listFirstNValuesOnly: n => `Elencare solo ${n} valori`,
          apply: 'Applicare',
          noRecordIsRead: 'Nessun documento trovato',
          readonlyColumnDetected: 'Rilevata colonna di sola lettura',
          columnHasValidationError: (name, err) => `La colonna ${name} ha un errore di validazione: ${err}`,
          noMatchedColumnName: 'Nessuna colonna corrispondente al nome',
          invalidInputValue: 'Valore immesso non valido',
          missingKeyColumn: 'Chiave colonna mancante',
          noRecordIndicator: 'Nessun documento'
        },
        obj:{
          "Codice": "",
          "SLCode": "",
          "SLDescription": "",
          "RouteDiscountBase": 0,
          "RouteDiscount_2": 0,
          "RouteDiscount_3": 0,
          "PD_MDCELDOM": 0,
          "PD_MDC_VOLUME_ZONE": 0,
          "PD_AIR_CLEANER": 0,
          "PD_SPLIT_02MULTI": 0,
          "PD_SENSIRA": 0,
          "PD_VOLUME_ZONE": 0,
          "PD_SKY_PACKAGE": 0,
          "PD_VAM": 0,
          "PD_VRV": 0,
          "PD_MINIVRV": 0,
          "PD_CHILLER_UTA": 0,
          "PD_MINI_CHILLER": 0,
          "PD_SMALL_CHILLER": 0,
          "PD_FAN_COIL": 0,
          "PD_ACCESSORI_RESIDENZIALI": 0,
          "PD_ACCESSORI": 0,
          "AD_MULTI": 0,
          "AD_SPLIT": 0,
          "AD_VRV_MINI_VRV": 0,
          "AD_MINI_CHILLER": 0,
        },
      };
    },
    computed: {

      
      displayedPosts() {
        return this.paginate(this.itemList);
      },
      resultQuery() {
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          console.log(this.displayedPosts)
          return this.displayedPosts.filter((data) => {
            return (
              data.code.toLowerCase().includes(search) ||
              data.ragSoc.toLowerCase().includes(search) ||
              data.partitaIva.toLowerCase().includes(search) ||
              data.sigla.toLowerCase().includes(search) ||

              data.email.toLowerCase().includes(search)
            );
          });
        } else {
          return this.displayedPosts;
        }
      },
      
    },


    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {

      this.axiosInterceptor()
      axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
                
              }
            }
          }
      });
    },
    mounted() {
      this.getList();
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      ...mapActions('layout', ['changeLayoutType', ]),
      axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
              
              return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
              Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')              
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
      },
      SearchData(){
        if (this.select != ''){
          this.getList()
        }
      },
      gotTo(name,id) {
        document.getElementById("edtclosemodal").click();
        this.$router.push({name:name, params:{id: id}})
      },
      getParent(){
        this.getList()
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}sales-route/per-page/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.itemList = response.data.results.data.filter(function(el){
              return Object.keys(el).length > 6
            })
            this.paginated = response.data.results.data.length
        })
      },
      getItem(id){
       this.axiosInterceptor()
       axios.get(`${this.UrlServer}sales-route/${id}`, 
        { 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
          this.dataEdit = response.data
        })
      },

      editdata(data) {
        let result = this.itemList.findIndex(
          (index) => index._id == data._id
        );
       
       
        document.getElementById("edtidfield1").value =
          this.itemList[result]._id;
         this.dataEdit = this.itemList[result]
      },
      initItem(){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}sales-route/register`,this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
             this.sortRes = 'desc' 
             this.getList()
          })
      },
      selectDataEdit(){
        if (this.cookies.get('brand-search-form')) {
          let id = this.cookies.get('brand-search-form')
          this.getItem(id)
        }
      },
      updatedata(id,obj) {
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}sales-route/${id}`,obj, 
         {headers: { authorization:this.tk}} )
          .then(() => {
            
          })
      },
      deletedata(event) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
            this.itemList.splice(this.itemList.indexOf(event), 1);
            try {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}sales-route/${event._id}`)
                .then(() => {
                  
                  this.getList()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                })
            }catch(error){
            
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.itemList.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(itemList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return itemList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      deleteMultiple() {
        let ids_array = [];
        var items = document.getElementsByName("chk_child");
        items.forEach(function (ele) {
          if (ele.checked == true) {
            var trNode = ele.parentNode.parentNode.parentNode;
            var id = trNode.querySelector(".id a").innerHTML;
            ids_array.push(id);
          }
        });
        if (typeof ids_array !== "undefined" && ids_array.length > 0) {
          if (confirm("Are you sure you want to delete this?")) {
            var cusList = this.itemList;
            ids_array.forEach(function (id) {
              console.log(id);
              cusList = cusList.filter(function (item) {
                return item.itemId != id;
              });
            });
            this.itemList = cusList;
            document.getElementById("checkAll").checked = false;
            var itemss = document.getElementsByName("chk_child");
            itemss.forEach(function (ele) {
              if (ele.checked == true) {
                ele.checked = false
                ele.closest("tr").classList.remove("table-active");
              }
            });
          } else {
            return false;
          }
        } else {
          Swal.fire({
            title: "Please select at least one checkbox",
            confirmButtonClass: "btn btn-info",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      },
      onBeforeNameChange (...args) {
        console.log(...args)  // show all the arguments: newVal, oldVal, oldRow, field
      },
      onBeforePhoneChange (newVal) {
          // This example demonstrate how to ensure the uniqueness of the phone number
          if (this.jsondata.findIndex(row => row.phone === newVal) >= 0)
              return false  // return false to reject the update
      },
      onChangeField (newVal, oldVal, row, keyField) {
        const handler = {
          get: function (target, property) {
            return target[property];
          },
          set: function (target, property, value) {
            
            target[property] = value;

          },
        };
        
        let proxyKeyField = new Proxy(keyField, handler);
        let items = this.itemList.filter(function(el){
          return el._id == row._id
        })
        this.obj = items[0]
        this.obj[proxyKeyField.name] = parseInt(newVal)
        console.log(this.obj )
        this.updatedata(row._id,this.obj)
      },
      exportAsExcel () {
        const format = 'xlsx'
        const exportSelectedOnly = true
        const filename = 'Canali'
        this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
      },
      exportAsCsv () {
          const format = 'csv'
          const exportSelectedOnly = true
          const filename = 'canali'
          this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
      }
    },
    components: {
      Layout,
      PageHeader,
      documentImportManager,
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="itemList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <!-- Doc Manager -->
              <documentImportManager 
                  storageType="salesroute-import"
                  :url="`${this.UrlServer}storage/by/import`"
                  :data="item" 
                  bucket="public/data" 
                  type="importazioni" 
                  fileType="multiple" 
                  fileAccept="*" 
                  title="Gestisci file di importazione"
                  
                  />
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom">
            <form>
              <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
                <div class="col-md-12 col-lg-12">
                    <div class="mb-3 form-icon">
                        <input
                            type="text"
                            id="search"
                            v-model="search"
                            placeholder="Digita qui termine di ricerca ..."
                            class="form-control-search p-3 mt-20 mb-0.5 w-full border border-blue-300 rounded"
                            @input="getList()"
                        >                        
                    </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <button type="button" @click="exportAsExcel"> Export Excel </button>
            <button @click="exportAsCsv"> Export CSV </button>
            <vue-excel-editor v-model="itemList" :localized-label="myLabels" no-paging add-column allow-add-col>
              <vue-excel-column field="SLCode"   label="Codice canale" type="string" width="80px" />
              <vue-excel-column field="SLDescription"   label="Descrizione" type="string" width="250px" :change="onChangeField"/>
              <vue-excel-column field="RouteDiscountBase"  label="Sconto Base" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="RouteDiscount_2" label="Sconto 2" type="number" width="130px"  :change="onChangeField" key-field />
              <vue-excel-column field="RouteDiscount_3"    label="Sconto 3" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_MDCELDOM"    label="13 MDC ELDOM" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_MDC_VOLUME_ZONE"    label="22 MDC VOLUME ZONE" type="number" width="180px" :change="onChangeField"/>
              <vue-excel-column field="PD_AIR_CLEANER"    label="10 AIR CLEANER" type="number" width="180px" :change="onChangeField"/>
              <vue-excel-column field="PD_SPLIT_02MULTI"    label="01 SPLIT-02 MULTI" type="number" width="180px" :change="onChangeField"/>
              <vue-excel-column field="PD_SENSIRA"    label="SENSIRA" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_VOLUME_ZONE"    label="15 VOLUME ZONE" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_SKY_PACKAGE"    label="03 SKY - 05 PACKAGE" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_VAM"    label="04 VAM" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_VRV"    label="07 VRV" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_MINIVRV"    label="MINI VRV" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_CHILLER_UTA"    label="08 CHILLER - 23 UTA - 20 CENTRIFUGHI" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_MINI_CHILLER"   label="26 MINI CHILLER" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_SMALL_CHILLER"   label="12 SMALL CHILLER" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_FAN_COIL"   label="06 FAN COIL" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_ACCESSORI_RESIDENZIALI"  label="16 ACCESSORI RESIDENZIALI" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_ACCESSORI"   label="09 ACCESSORI" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="AD_MULTI"   label="MULTI" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="AD_SPLIT"   label="SPLIT" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="AD_VRV_MINI_VRV"   label="VRV MINI VRV" type="number" width="130px" :change="onChangeField"/>
              <vue-excel-column field="PD_MINI_CHILLER"   label=" MINI CHILLER SMALL CHILLER - CHILLER -CENTRIFUGHI - FANCOIL  - UTA" type="number" width="130px" :change="onChangeField"/>
            </vue-excel-editor>
   
          </div>
        
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>